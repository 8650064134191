/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.level-container .level1 {
  display: flex;
  padding-bottom: 20px;
  position: relative;
}
.level-container .level1:first-child .level1-case:after {
  content: '';
  display: block;
  position: absolute;
  top: 14px;
  left: 15px;
  width: 120px;
  height: 0px;
  border-bottom: 1px dashed #cccc;
}
.level-container .level1:last-child .level1-case:before {
  display: none;
}
.level-container .level1 .level1-case {
  width: 60px;
  height: 100%;
}
.level-container .level1 .level1-case:before {
  content: '';
  display: block;
  position: absolute;
  top: 14px;
  left: 15px;
  width: 120px;
  height: 100%;
  border-bottom: 1px dashed #cccc;
  border-left: 1px dashed #ccc;
}
.level-container .level1 .level2 {
  display: flex;
  align-items: center;
  height: 35px;
}
.level-container .level1 .level2:first-child .level2-case:before {
  display: none;
}
.level-container .level1 .level2 .level2-case {
  width: 60px;
  position: relative;
  height: 100%;
}
.level-container .level1 .level2 .level2-case:before {
  content: '';
  display: block;
  position: absolute;
  bottom: 50%;
  right: 0;
  width: 46px;
  height: 40px;
  border-bottom: 1px dashed #cccc;
  border-left: 1px dashed #ccc;
}
.level-container .level1 .level2 .level2-content {
  display: flex;
  padding-right: 10px;
  gap: 10px;
}
.level-container .level1 .level2 .level2-ops {
  display: flex;
}
.level-container .level1 .level2 .level2-ops .op {
  margin-right: 10px;
}
.case-switcher {
  border: 1px solid #ccc;
  width: 30px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  position: relative;
  z-index: 1;
  background: #fff;
}
.expandedRow-table .expandedRow-table-row {
  display: grid;
  grid-template-columns: 33% 33% 33%;
}
.expandedRow-table .expandedRow-table-head {
  position: relative;
  overflow-y: scroll;
  font-weight: bold;
}
.expandedRow-table .expandedRow-table-head::-webkit-scrollbar {
  background-color: transparent;
}
.expandedRow-table .expandedRow-table-body .expandedRow-table-row:last-child .expandedRow-table-col {
  border-bottom: 0;
}
.expandedRow-table .expandedRow-table-col {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 5px;
  border-bottom: 1px solid #dfdfdf;
}
.expandedRow-table .expandedRow-table-body .expandedRow-table-col {
  padding: 12px 8px;
}
.expandedRow-table .expandedRow-table-head {
  font-weight: bold;
}
